*, 
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Inter';
}

html {
  width: 100vw;
  height: 100vh;
}

body{
  font-size: 100%;
  list-style-type: none;
}

.w-100 {
  width: 100%;
}

.w-100-vw {
  width: 100vw;
}

.w-50 {
  width: 50%;
}

.w-50-vw {
  width: 50vw;
}

.h-100 {
  height: 100%;
}

.h-100-vh {
  height: 100vh;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-32 {
  font-size: 32px;
}

.fs-64 {
  font-size: 64px;
}

.weight-100 {
  font-weight: 100;
}

.weight-200 {
  font-weight: 200;
}

.weight-300 {
  font-weight: 300;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

.weight-800 {
  font-weight: 800;
}

.weight-900 {
  font-weight: 900;
}

.gap-2 {
  gap: 2px;
}

.gap-10 {
  gap: 10px;
}

.flex-ac, .flex-jc, .flex-r, .flex-c, .flex-je, .flex-jsa {
  display: flex;
}

.flex-jc {
  justify-content: center;
}

.flex-ac {
  align-items: center;
}

.flex-r {
  flex-direction: row;
}

.flex-c {
  flex-direction: column;
}

.flex-je {
  justify-content: end;
}

.flex-jsa {
  justify-content: space-around;
}

.bg-orange {
  background-color: #FF5000;
}

.txt-orange {
  color: #FF5000;
}

.bg-light-orange {
  background-color: #FFBB00;
}

.txt-light-orange {
  color: #FFBB00;
}

.bg-white {
  background-color: #FFFFFF;
}

.txt-white {
  color: #FFFFFF;
}

.bg-light-gray {
  background-color: #F5F5F5;
}

.txt-light-gray {
  color: #7B809A;
}

.txt-gray {
  color: #585757;  
}

.txt-black {
  color: #01002E;
}

.overflow-hidden {
  overflow: hidden;
}

.overfow-x-hidden {
  overflow-x: hidden;
}

.max-500 {
  max-width: 500px;
}

.login-content-container {
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.pointer {
  cursor: pointer;
}

.navbar {
  max-width: 288px;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  box-shadow: inset -1px 0px 0px #D9D9D8;
}

.container-itens-navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navbar-itens {
  width: 100%;
  margin-bottom: 2rem;
}

.navbar-item {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  color: #585757;
  cursor: pointer;
  font-size: 16px;
}

.navbar-item img {
  margin-right: 10px;
  padding-left: 2rem;
}

.navbar-item[aria-selected=true] {
  color: #01002E;
  background: #FFEDBB26;
  border-left: 3px solid #FFC21A;
  font-weight: 500;
}

.navbar-item[aria-selected=true] img {
  padding-left: 1.8rem;
}

.MuiButton-startIcon {
  margin: 0 !important;
  display: block !important;
}

.content-container {
  width: 100%;
  padding: 3rem 3rem 0 3rem;
}

.header {
  width: 100%;
  padding: 1rem 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.perfil-image {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: .2px solid #969696;
  border-radius: 100%;
  background-color: #FFFFFF;
}

.m-icon-header {
  margin: 0 .5rem 0 1rem;
}

.main-content {
  background-color: #FFFFFF;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 1rem;
}

div .css-1ps6pg7-MuiPaper-root {
  box-shadow: none !important;
  border-radius: 0 !important;
  filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.06));
}

div .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.status-active {
  width: 58px;
  height: 22px;
  padding: 2px 8px 2px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #027A48;
  background-color: #ECFDF3;
  border-radius: 16px;
  gap: 4px;
}

.status-inative {
  width: 69px;
  height: 22px;
  padding: 2px 8px 2px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #B42318;
  background-color: #FEF3F2;
  border-radius: 16px;
  gap: 4px;
}

.search-container {
  display: flex;
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  gap: 14px;
  margin-bottom: 1rem;
}

.search-container button {
  width: 350px;
  font-size: 13px;
}
